<template>
  <div>
    <h3 class="mb-4 color-primary-light">
      Follow Australia Post instructions to receive a barcode or print your own label
    </h3>
    <h4 class="mb-4">Thank you for getting your product ready for shipping!</h4>
    <p>You will be redirected to Australia Post site shortly.</p>
    <p>Please have the following details ready:</p>
    <ul>
      <li>Claim Number</li>
      <li>Your Email address</li>
      <li>Full Name</li>
      <li>Mobile Number</li>
      <li>Address line</li>
      <li>City / Suburb</li>
      <li>State</li>
      <li>Postcode</li>
    </ul>
    <p>
      Upon receiving a barcode or a shipping label – please take your product to the closest
      Australia Post branch.
    </p>
    <p>Ready to get your barcode?</p>
    <a class="btn btn-next bg-red mt-3" :href="redirectUrl">
      <strong class="color-white">Next</strong>
    </a>
  </div>
</template>

<script>
import { devices } from '@/shared';

export default {
  beforeRouteLeave(to, from, next) {
    next(false);
  },
  data() {
    return {
      path: this.$route.fullPath.split('/'),
      deviceFromParam: this.$route.params.device,
      typeSelected: this.$route.query.type
    }
  },
  props: {
    device: {
      default: null,
    }
  },
  computed: {
    devicekey() {
      if(this.path[1] === 'revive-dyson') {
        return Object.keys(devices.dyson).find((key) => devices.dyson[key].route === this.device);
      }
      return Object.keys(devices.apple).find((key) => devices.apple[key].route === this.device);
    },
    redirectUrl() {
      const redirectUrlApple = devices.apple[this.devicekey]?.redirect;
      let redirectUrlDyson =  devices.dyson[this.devicekey]?.redirect;
      const redirectBoxIntl = 'REVIVE_TECHNOLOGIES_INTERNATIONAL_PTY_LTD'
      if(this.path[1] === 'revive-dyson') {
          if(this.deviceFromParam === 'hair-dryer' && this.typeSelected === 'no') {
            redirectUrlDyson = `${redirectUrlDyson}/REVIVE_TECHNOLOGIES_BOX_3`;
          }
          if( ['stick-vacuums','air-wrap'].includes(this.device) && this.typeSelected === 'no') {
            redirectUrlDyson = `${redirectUrlDyson}/REVIVE_TECHNOLOGIES_BOX_5`;
          }
        if( ["hair-dryer", "air-wrap", "stick-vacuums"].includes(this.device) && this.typeSelected === 'yes' ) {
          redirectUrlDyson = `${redirectUrlDyson}/${redirectBoxIntl}`;
        }
        if( ['air-purifiers','barrel-vacuums', 'upright-vacuums'].includes(this.device) ) {
          redirectUrlDyson = `${redirectUrlDyson}/${redirectBoxIntl}`;
        }
          return redirectUrlDyson;
      }
        return redirectUrlApple;
    },
  },
  created() {
    setTimeout(() => {
      window.location.href = this.redirectUrl;
    }, 60000);
  }

};
</script>

<style></style>
